import router from "@/router/index"
const mutations = {
  addTab(state, child) {
    let tabs = state.editableTabs
    let bd = tabs.filter((i) => {
      return i.name == child.patch
    })
    if (bd.length == 0) {
      state.editableTabs.push({
        title: child.name,
        name: child.patch,
      })
    }
    state.editableTabsValue = child.patch
    //防重复添加
    let str = child.patch.substring(1).toLocaleUpperCase()
    for (let i = 0; i < state.keepaliveArr.length; i++) {
      if (state.keepaliveArr.indexOf(str) < 0) {
        state.keepaliveArr.push(str)
      }
    }
  },
  removeTab(state, targetName) {
    let tabs = state.editableTabs

    let activeName = state.editableTabsValue
    if (activeName === targetName) {
      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          let nextTab = tabs[index + 1] || tabs[index - 1]
          if (nextTab) {
            activeName = nextTab.name
            router.push(nextTab.name)
          } else {
            state.editableTabs = []
            router.push("/content")
          }
        }
      })
    }
    //移除之后删掉库中的可缓存组件名
    state.keepaliveArr = state.keepaliveArr.filter((i) => {
      return i !== targetName.substring(1).toLocaleUpperCase()
    })
    state.editableTabsValue = activeName
    state.editableTabs = tabs.filter((tab) => tab.name !== targetName)
  },
  CaiDanLan(state, CaiDanLan) {
    state.CaiDanLan = CaiDanLan
    let arr = []
    CaiDanLan.forEach((i) => {
      i.child.forEach((j) => {
        arr.push(j.patch.substring(1).toLocaleUpperCase())
      })
    })
    state.keepaliveArr = arr
  },
  showCaidan(state, caidan) {
    let showcai = []
    let showrouter = []
    //把菜单栏和路由总数据拿过来
    let carr = state.caidanData
    let rarr = state.routerData

    //循环权限数组 取出对应的菜单栏和路由

    //循环登录传进来的权限数字，对应路由和菜单栏
    for (let i of caidan) {
      //循环整个菜单栏
      for (let j of carr) {
        //循环菜单栏中的子项菜单
        for (let x of j.child) {
          //判断权限数字和子项菜单是否对应
          if (i == x.id) {
            //查找准备放入菜单栏的数组中 是否已经含有第一层菜单，没有返回的是 undefined
            let isc = showcai.find((itme) => itme.id == j.id)
            if (!isc) {
              //没有找到就放进第一次菜单加第一个子项
              showcai.push({ id: j.id, name: j.name, child: [x] })
            } else {
              //找到有第一层就直接放进子项
              showcai.forEach((z) => {
                if (z.id == j.id) {
                  z.child.push(x)
                }
              })
            }
          }
        }
      }
      //放入对应路由
      rarr.forEach((b) => {
        if (b.id == i) {
          showrouter.push(b)
        }
      })
    }
    state.caidan = showcai
    state.route = showrouter
  },
}

export default mutations
