<template>
  <div id="app">
    <transition name="slide-fade">
      <router-view name="AppPage" />
    </transition>
  </div>
</template>
<script>
export default {
  name: "App",
  mounted () {
    // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
    window.onbeforeunload = function () {
      const storage = window.localStorage
      storage.clear()
    }
  }
}
</script>

<style lang="less" sco>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.slide-fade-enter,.slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s ease;
}
.slide-fade-enter-to,
.slide-fade-leave {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.2s ease;
}
</style>
