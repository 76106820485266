import router from "@/router/index"
import store from "@/store/index"

//路由守卫，包含动语路由刷新无页面处理
router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem("token")
  if (to.path === "/login") {
    return next()
  }
  if (!token) {
    return next("/login")
  } else {
    //判断是否有值 无值代表用户刷新 需重新添加路由及菜单
    const route = JSON.parse(window.sessionStorage.getItem("route"))
    if (store.state.route.length == 0) {
      if (route[0] == "") {
        console.log("用户无权限")
      } else {
        setRoutes()
        next({ path: to.path })
      }
    }
    return next()
  }
})
//添加路由
const setRoutes = function () {
  const route = JSON.parse(window.sessionStorage.getItem("route"))
  store.commit("showCaidan", route)
  const routerData = store.state.route
  router.reloadRouter()
  for (let i in routerData) {
    const temp = {
      path: routerData[i].path,
      name: routerData[i].name,
      components: {
        contentpage: () => import(`@/components/${routerData[i].patch}`),
      },
    }
    router.addRoute("content", temp)
  }
}
