// import axios from "axios"
// import ElementUI from "element-ui"

// const request=axios.create({
//   baseURL:"http://localhost:8081/",
//   timeout:8000//超时时间
// })
// //请求拦截
// request.interceptors.request.use(
//   (config) => {
//     //部分接口需要拿到token
//     let token = sessionStorage.getItem("token")
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`
//     }
//     return config
//   },
//   (err) => {
//     return Promise.reject(err)
//   },
// )
// //拦截器 响应拦截
// request.interceptors.response.use(
//   (res) => {
//     return res
//   },
//   (err) => {
//     if (err.response.status == "401") {
//       ElementUI.Message.error("登录超时，请退出系统重新登录")
//       this.$router.push("/login")
//       window.sessionStorage.removeItem("user")
//       window.sessionStorage.removeItem("route")
//       window.sessionStorage.removeItem("role")
//     }else if (err.response.status == "400") {
//       ElementUI.Message.error("登录失败，账号或密码错误")
//     }
//     else {
//       ElementUI.Message.error(
//         err.response.status + "请求失败，服务器后端报错，请联系开发人员"
//       )
//     }

//     return err
//   },
// )
// export default request
import axios from "axios";

const request = axios.create({
  baseURL: "/api/",
  timeout: 300000,
})
// 请求拦截
request.interceptors.request.use(
  (config) => {
    //部分接口需要拿到token
    let token = sessionStorage.getItem("token")
    if (token) {
      config.headers.Authorization = token; 
    }
    if (config.method.toUpperCase() === 'OPTIONS') {  
      // 如果是 OPTIONS 请求，则直接返回配置，不执行额外的逻辑  
      return config;  
    }  
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

request.interceptors.response.use((response) => {
  //  if(response.data=="401"){
  //   localStorage.removeItem("token");
  //   window.location.reload();
  //  }
  return response;
})

export default request