import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations.js'

Vue.use(Vuex)

const state = {
	xinZeng: {},
	//需要缓存的组件名字 是组件名 不是路由名
	keepaliveArr: [],
	editableTabsValue: '',
	editableTabs: [],
	CaiDanLan: [],

	//路由数据
	routerData: [
		{
			id: 1,
			path: '/excelusercrt',
			name: 'excelusercrt',
			patch: 'excel/usercrt.vue',
		},
		{
			id: 2,
			path: '/excelbankcrt',
			name: 'excelbankcrt',
			patch: 'excel/bankcrt.vue',
		},
		{
			id: 3,
			path: '/excelfeecrt',
			name: 'excelfeecrt',
			patch: 'excel/feecrt.vue',
		},
		{
			id: 4,
			path: '/handusercrt',
			name: 'handusercrt',
			patch: 'hand/usercrt.vue',
		},
		{
			id: 5,
			path: '/handbankcrt',
			name: 'handbankcrt',
			patch: 'hand/bankcrt.vue',
		},
		{
			id: 6,
			path: '/handfeecrt',
			name: 'handfeecrt',
			patch: 'hand/feecrt.vue',
		},
		{
			id: 7,
			path: '/user',
			name: 'user',
			patch: 'user.vue',
		},
	],
	//菜单栏权限数据id与路由id对应
	caidanData: [
		{
			id: '01',
			name: '报表',
			child: [
				{
					id: 1,
					name: '协旺食品报表',
					patch: 'excelusercrt',
				},
				// {
				// 	id: 2,
				// 	name: '银行对照表',
				// 	patch: 'excelbankcrt',
				// },
				// {
				// 	id: 3,
				// 	name: '费用对照表',
				// 	patch: 'excelfeecrt',
				// }
			],
		},

		// {
		// 	id: '02',
		// 	name: '手工录入',
		// 	child: [
		// 		{
		// 			id: 4,
		// 			name: '制单人对照表',
		// 			patch: 'handusercrt',
		// 		},
		// 		{
		// 			id: 5,
		// 			name: '银行对照表',
		// 			patch: 'handbankcrt',
		// 		},
		// 		{
		// 			id: 6,
		// 			name: '费用对照表',
		// 			patch: 'handfeecrt',
		// 		}
		// 	]
		// },
		// {
		// 	id: '03',
		// 	name: '用户管理',
		// 	child: [
		// 		{
		// 			id: 7,
		// 			name: '用户',
		// 			patch: 'user',
		// 		}
		// 	]
		// }
	],
	//正在使用的菜单和路由
	caidan: [],
	route: [],
}

export default new Vuex.Store({
	state,
	mutations,
})
