import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import "./router/permission"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import global from "@/assets/global.css"
import request from "./API/request"
import Print from 'vue-print-nb'
import JsonExcel from 'vue-json-excel'
import 'default-passive-events'



Vue.component('downloadExcel', JsonExcel)
Vue.use(Print)
Vue.prototype.$axios = request
Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  global,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
